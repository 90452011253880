
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'VideoDialog',
})
export default class VideoDialog extends Vue {
    @Prop(String) private videoSrc!: "";
    private showDialog: boolean = false; // 弹窗显示与关闭

    @Prop({
        type: Boolean,
        default: false,
    })
    private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        const myVideo: any = document.getElementById("myvideo");
        this.showDialog = value;
        if (value && myVideo) {
            myVideo.play();
        }
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        return num;
    }
    // 右上角的关闭按钮
    private beforeclose() {
        const myVideo: any = document.getElementById("myvideo");
        if (myVideo) {
            myVideo.pause();
        }
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }

}
